<template>
  <div class="project-pool__wrapper">
    <IdeaHeader isLayout="ideas" title="Proje Havuzu" />
    <IdeaForm
      class="my-idea-container"
      @search-filter="ideaSearchFilter"
      @clear-search-filter="clearSearchFilter"
      isIdea="my-idea"
      isForm="projectPool"
      suggestionTypeId="1"
    />
    <div
      v-if="getProjectPoolData && getProjectPoolData.length > 0"
      style="margin: 0 15px"
      class="ideas-table-wrapper"
    >
      <AdminTable
        :columns="columns"
        :rows="getProjectPoolData"
        @table-detail="cardDetail"
        isLayout="idea"
      />
      <div class="row mt-4 d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <NotIdea v-else />
    <transition name="fade">
      <IdeaDetail
        v-if="isIdeaModal"
        @close="isIdeaModal = $event"
        :detailData="ideaModalData"
        isSuggestion="idea"
      />
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { upload } from "../../services";

import AdminTable from "../../components/Admin/AdminTable.vue";
import IdeaForm from "../../components/MyIdea/IdeaForm.vue";
import IdeaHeader from "../../components/MyIdea/IdeaHeader.vue";
import NotIdea from "../../components/MyIdea/NotIdea.vue";
import IdeaDetail from "../../components/Main/Cards/Modals/CardDetail.vue";
export default {
  name: "ProjectPool",
  components: {
    IdeaHeader,
    IdeaForm,
    AdminTable,
    NotIdea,
    IdeaDetail,
  },
  data() {
    return {
      ideaModalData: null,
      isIdeaModal: false,
      columns: [
        {
          label: "Fikir No.",
          field: "SuggestionNumber",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Yıl",
          field: "ProjectYear",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Departman",
          field: "SharePointDepartment",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Proje Adı",
          field: "Title",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Ekip Lideri",
          field: "SuggestionRDUserModelsTeamLead",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Değer Akımı Yöneticisi",
          field: "SuggestionRDUserModelsValueCurrentManager",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Statü",
          field: "SuggestionStatusName",
          sortable: false,
          thClass: "vgt-center-align",
        },
      ],
      searchText: "",
      perPage: 15,
      currentPage: 1,
      isShareIdea: false,
      editPagedData: null,
      isCardDelete: false,
      ideaFilterData: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      totalRecords: "projectPool/projectPoolTotalRecords",
    }),
    getProjectPoolData() {
      return this.$store.getters["projectPool/getProjectPoolData"];
    },
  },
  watch: {
    currentPage() {
      this.searchFilter(this.ideaFilterData);
    },
  },
  methods: {
    clearSearchFilter(event) {
      this.searchFilter(event);
    },
    ideaSearchFilter(event) {
      this.currentPage = 1;
      this.searchFilter(event);
    },
    searchFilter(event) {
      let tmpData = null;
      this.ideaFilterData = event;
      tmpData = {
        ...{
          pageSize: this.perPage,
          pageIndex: parseInt(this.currentPage - 1),
          loginUserId: this.user.Data.UserId,
        },
        ...event,
      };
      this.$store.dispatch("projectPool/deleteProductData");
      this.$store.dispatch("projectPool/setProjectPoolData", tmpData);
    },
    async cardDetail(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      this.ideaModalData = item;
      this.ideaModalData.ProjectPresentationDocumentFiles = [];
      this.ideaModalData.DownloadFile = [];

      if (this.ideaModalData.FileIds && this.ideaModalData.FileIds.length > 0) {
        for await (let element of this.ideaModalData.FileIds) {
          const res = await upload.fetchDownloadFile(element);
          this.ideaModalData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      if (
        this.ideaModalData.ProjectPresentationDocumentIds &&
        this.ideaModalData.ProjectPresentationDocumentIds.length > 0
      ) {
        for await (let element of this.ideaModalData
          .ProjectPresentationDocumentIds) {
          const res = await upload.fetchDownloadFile(element);
          this.ideaModalData.ProjectPresentationDocumentFiles.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isIdeaModal = true;
    },
  },
  async created() {
    if (this.getProjectPoolData.length == 0) this.searchFilter();
  },
};
</script>

<style></style>
