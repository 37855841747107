<template>
  <div
    class="row justify-content-center"
    id="ideasList"
    style="margin-left: 20px;margin-right: 20px;margin-top: 20px"
  >
    <div class="innovation-none-info mx-3 my-5">
      <p>
        Giriş yaptığınız fikir bulunmamaktadır.<br />
        Fikir girişi için lüften <br class="d-sm-none" /><a class="link"
          @click="$router.push('/')"
          >anasayfa</a
        >ya gidiniz.
      </p>

      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 100 90"
        style="enable-background:new 0 0 100 90;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M49.92,63.09c-2.79,0-5.13,2.3-5.13,5.04c0,2.74,2.34,5.04,5.13,5.04c2.69,0,5.13-2.3,5.01-4.92 C55.05,65.37,52.73,63.09,49.92,63.09L49.92,63.09z M49.92,63.09"
          ></path>
          <path
            class="st0"
            d="M97.42,80.96c3.22-5.47,3.24-11.98,0.04-17.43L65.32,8.82c-3.18-5.51-8.93-8.78-15.37-8.78 S37.75,3.33,34.57,8.8L2.39,63.57c-3.2,5.51-3.18,12.06,0.06,17.53c3.2,5.41,8.93,8.66,15.33,8.66h64.2 C88.41,89.76,94.18,86.47,97.42,80.96L97.42,80.96z M90.44,77.01c-1.79,3.03-4.95,4.82-8.48,4.82h-64.2 c-3.49,0-6.63-1.76-8.37-4.72c-1.77-3.01-1.79-6.6-0.02-9.62l32.18-54.75c1.74-3.01,4.86-4.78,8.39-4.78c3.51,0,6.65,1.8,8.39,4.8 L90.5,67.51C92.23,70.45,92.21,74,90.44,77.01L90.44,77.01z M90.44,77.01"
          ></path>
        </g>
        <path
          d="M48.65,27.68c-2.44,0.69-3.96,2.86-3.96,5.51c0.12,1.59,0.23,3.21,0.35,4.8c0.35,6.07,0.7,12.02,1.05,18.1 c0.12,2.06,1.74,3.55,3.84,3.55c2.09,0,3.74-1.59,3.84-3.67c0-1.25,0-2.4,0.12-3.67c0.23-3.89,0.47-7.79,0.7-11.68c0.12-2.52,0.35-5.04,0.47-7.57c0-0.91-0.12-1.72-0.47-2.52C53.54,28.27,51.09,27.11,48.65,27.68L48.65,27.68z M48.65,27.68"
        ></path>
      </svg>
    </div>

    <div class="row pt-5">
      <nav id="tbl-pagination" class="mx-auto my-ideas-pagination">
        <ul class="pagination"></ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotIdea",
};
</script>

<style></style>
